.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 40px;
  touch-action: pan-y pinch-zoom;
  -webkit-user-select: none;
  user-select: none;
}

.carousel-header {
  width: 80%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}

.carousel-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel {
  display: block;
  flex: 1;
  justify-content: center;
  touch-action: pan-y pinch-zoom;
  -webkit-user-select: none;
  user-select: none;
}

.carousel-item {
  display: none;
}

.carousel-item.active {
  display: block;
}

.custom-card {
  margin: 0 10px;
  box-shadow: 3;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.card-chip {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
}
